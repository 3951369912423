import { StaticImage } from 'gatsby-plugin-image';
import React, { ReactNode } from 'react';

interface ThumbnailProps {
  blurb: string;
  min: string;
  price: string;
  startingAt: string;
  thumb: string;
  title: string;
}

export function Thumbnail({
  blurb,
  min,
  price,
  startingAt,
  thumb,
  title,
}: ThumbnailProps) {
  return (
    <>
      <div className="relative block h-60">
        <div className="block overflow-hidden rounded h-60">{thumb}</div>

        <div className="absolute bottom-0 z-10 w-full p-4 pt-5 text-white bg-gray-800 bg-opacity-75 rounded-b pointer-events-none md:flex-row backdrop-blur backdrop-filter">
          <div className="flex flex-col items-center md:flex-row">
            <h2
              className="m-0 text-xl text-center uppercase md:mb-0 font-display md:text-left"
              style={{ width: '60%' }}
            >
              {title}
            </h2>
            <div className="flex ml-0 text-center md:text-right md:ml-auto">
              <p className="mb-0">
                {startingAt && (
                  <span className="block text-xs opacity-75">Starting at</span>
                )}
                <strong className="block text-xl">${price}</strong>
                {!startingAt && (
                  <span className="block text-xs opacity-75">+ tax/each</span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      {min && (
        <p className="justify-center w-full mb-0 text-xs text-center">
          {blurb && <em className="block mb-2 opacity-75">{blurb}.</em>}
          <strong className="text-black opacity-75">{min}.</strong>
        </p>
      )}
    </>
  );
}

interface WideThumbnailProps {
  children: ReactNode;
  min: string;
  thumb: string;
  title: string;
}

export function WideThumbnail({
  children,
  min,
  thumb,
  title,
}: WideThumbnailProps) {
  return (
    <>
      <div className="relative block overflow-hidden h-96">
        <div className="block overflow-hidden rounded h-96">{thumb}</div>

        <div className="absolute bottom-0 z-10 w-full p-4 pt-5 text-white bg-gray-800 bg-opacity-75 rounded-b pointer-events-none md:flex-row backdrop-blur backdrop-filter">
          <div className="flex flex-col items-center md:flex-row">
            <h2
              className="m-0 text-xl text-center uppercase md:mb-0 font-display md:text-left"
              style={{ width: '25%' }}
            >
              {title}
            </h2>
            <div className="flex flex-col ml-0 text-center md:text-right md:ml-auto">
              {children}
            </div>
          </div>
        </div>
      </div>
      {min && (
        <p className="justify-center w-full mb-0 text-xs text-center">
          <strong className="text-black opacity-75">{min}.</strong>
        </p>
      )}
    </>
  );
}

export const Blurb = ({ children }: { children: ReactNode }) => {
  return (
    <div className="p-5 text-sm bg-gray-100 rounded md:col-span-2">
      {children}
    </div>
  );
};

export const Container = ({ children }: { children: ReactNode }) => {
  return <div className="flex flex-col space-y-4">{children}</div>;
};

export const IMAGES = {
  cones: (
    <StaticImage src="../images/corporate/cones.jpg" alt="Gray + Mae Cups" />
  ),
  luxsmall: (
    <StaticImage
      src={`../images/corporate/lux-02.webp`}
      alt="The Lux Small Box"
    />
  ),
  coming: (
    <StaticImage
      src={`../images/corporate/coming-soon.jpg`}
      alt="Coming Soon"
    />
  ),
  boats: (
    <StaticImage src={`../images/corporate/boats.jpg`} alt="Gray + Mae Boats" />
  ),
  cups: (
    <StaticImage src={`../images/corporate/cups.jpg`} alt="Gray + Mae Cups" />
  ),
  sweet: (
    <StaticImage
      src={`../images/corporate/sweet-mae-mini.jpg`}
      alt="Sweet Mae Mini"
    />
  ),
  snack: (
    <StaticImage src={`../images/corporate/snack-box.jpg`} alt="Snack Box" />
  ),
  grazing: (
    <StaticImage
      src={`../images/corporate/grazing-table.jpg`}
      alt="Grazing Table"
    />
  ),
};
