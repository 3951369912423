import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout.tsx";
import { StaticImage } from 'gatsby-plugin-image';
import { ContentBox, Title } from '../components/ContentBox';
import { Blurb, Container, IMAGES, Thumbnail, WideThumbnail } from '../components/Corporate';
import { CorporateForm } from '../components/forms/CorporateForm';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ContentBox mdxType="ContentBox">
      <div>
        <Title mdxType="Title">{props.pageContext.frontmatter.title}</Title>
        <div className="flex p-5 md:p-0 flex-col md:flex-row md:space-x-10 mt-10">
          <div className="w-full md:w-1/2">
            <div>
              <p><strong>Menu</strong></p>
              <p>{`Looking for something customized or specific? Tell us your vision and let us create something for you or simply choose between one of our menu items. We have many options to suit different budgets and price points.`}</p>
            </div>
            <div className="grid grid-cols-1 gap-4 mb-4">
  <Container mdxType="Container">
    <WideThumbnail thumb={IMAGES.grazing} title="Grazing Table" min="Minimum 30 people" mdxType="WideThumbnail">
      <p className="text-sm mb-1">Price dependant on number of guests and specifications.</p>
      <p className="text-xs opacity-75 mb-0">Please complete the form below to <br />receive an information package and quote.</p>
    </WideThumbnail>
  </Container>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <Container mdxType="Container">
  <Thumbnail thumb={IMAGES.cups} title="Gray + Mae Cups" price="8" min="Minimum order of 10" startingAt mdxType="Thumbnail" />
              </Container>
              <Container mdxType="Container">
  <Thumbnail thumb={IMAGES.cones} title="Gray + Mae Cones" price="10" min="Minimum order of 10" startingAt mdxType="Thumbnail" />
              </Container>
              <Container mdxType="Container">
  <Thumbnail thumb={IMAGES.boats} title="Gray + Mae Boats" price="12" min="Minimum order of 10" startingAt mdxType="Thumbnail" />
              </Container>
              <Container mdxType="Container">
  <Thumbnail thumb={IMAGES.snack} title="Snack Box with lid" price="20" min="Minimum order of 4" blurb="Feeds 1" mdxType="Thumbnail" />
              </Container>
            </div>
          </div>
          <div className="w-full md:w-1/2">
  <div>
              <p><strong>Custom Design</strong></p>
              <p>{`From logo artwork, custom menu cards and printed materials to cheese cutouts and event information cards, we can fully customize your order and create something truly unique to your brand or your event.`}</p>
            </div>
            <div>
              <p><strong>Order & Payment</strong></p>
              <p>{`we ask that all bulk orders be made at least 2 weeks in advance. Payment is due 2 weeks prior to the event date.`}</p>
            </div>
            <div>
              <p><strong>Delivery & Pickup</strong></p>
              <p>{`Bulk order delivery is available; the price is based on time and kms. Please provide us with the number of deliveries and a list of addresses to receive a quote. City-wide delivery is available for $10. Pickup is in Headingley.`}</p>
            </div>
            <h2>Request a quote</h2>
            <CorporateForm mdxType="CorporateForm" />
  </div>
        </div>
      </div>
    </ContentBox>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      