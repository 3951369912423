import React, { useState } from 'react';

import { Input } from './Input';
import { Label } from './Label';
import { Radio } from './Radio';

export const CORPORATE_ITEMS = [
  'Grazing Table',
  'Gray + Mae Cups',
  'Gray + Mae Cones',
  'Gray + Mae Boats',
  'Snack Box',
  'Sweet Mae Mini Box',
];

export const CorporateForm = () => {
  const [isDelivery, setIsDelivery] = useState(false);

  return (
    <form action="https://formspree.io/f/xnqlopvr" method="POST">
      <div className="overflow-hidden shadow sm:rounded-md">
        <div className="px-4 py-5 bg-white sm:p-6">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-700"
              >
                First name
              </label>
              <Input
                type="text"
                name="first-name"
                autoComplete="given-name"
                required
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium text-gray-700"
              >
                Last name
              </label>
              <Input
                type="text"
                name="last-name"
                autoComplete="family-name"
                required
              />
            </div>

            <div className="col-span-6 sm:col-span-4">
              <label
                htmlFor="_replyto"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <Input
                type="text"
                name="_replyto"
                autoComplete="email"
                required
              />
            </div>

            <div className="col-span-6 sm:col-span-2">
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700"
              >
                Phone number
              </label>
              <Input type="tel" name="phone" autoComplete="phone" required />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="date"
                className="block text-sm font-medium text-gray-700"
              >
                Event Date
              </label>
              <Input type="date" name="date" autoComplete="date" />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="guests"
                className="block text-sm font-medium text-gray-700"
              >
                Number of Guests
              </label>
              <Input type="number" name="guests" />
            </div>

            <div className="col-span-6">
              <label
                htmlFor="box_type"
                className="block text-sm font-medium text-gray-700"
              >
                Menu Item of Interest
              </label>
              <select
                multiple
                id="box_type"
                name="box_type"
                autoComplete="number"
                className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                {CORPORATE_ITEMS.map((item) => {
                  return <option key={item}>{item}</option>;
                })}
              </select>
            </div>

            <div className="col-span-6">
              <label
                htmlFor="details"
                className="block text-sm font-medium text-gray-700"
              >
                Details & Information
              </label>
              <div className="mt-1">
                <textarea
                  id="details"
                  name="details"
                  rows={3}
                  className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                ></textarea>
              </div>
            </div>
            {isDelivery && (
              <div className="col-span-6">
                <Label htmlFor="address">Address</Label>
                <div className="mt-1">
                  <Input type="text" name="address" required />
                </div>
              </div>
            )}
            <div className="col-span-6 space-y-2">
              <label
                htmlFor="quantity"
                className="flex items-center text-sm font-medium text-gray-700"
              >
                <Radio
                  name="delivery"
                  value="delivery"
                  onChange={() => setIsDelivery(true)}
                />
                Delivery (fee based on location)
              </label>

              <label
                htmlFor="quantity"
                className="flex items-center text-sm font-medium text-gray-700"
              >
                <Radio
                  name="delivery"
                  value="pickup"
                  onChange={() => setIsDelivery(false)}
                />
                Pickup (Headingley)
              </label>
            </div>
          </div>
        </div>
        <div className="px-4 py-3 text-right bg-gray-50 sm:px-6">
          <button
            type="submit"
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Send
          </button>
        </div>
      </div>
    </form>
  );
};
